
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --saffron-50: #fefaec;
  --saffron-100: #faf1cb;
  --saffron-200: #f5e292;
  --saffron-300: #f0cf59;
  --saffron-400: #eebf3f;
  --saffron-500: #e69c1a;
  --saffron-600: #cb7814;
  --saffron-700: #a95714;
  --saffron-800: #894317;
  --saffron-900: #713816;
  --saffron-950: #411c07;

}

body {

  font-family: 'Plus Jakarta Sans', sans-serif;
}
